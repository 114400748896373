<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editStatus')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createStatus')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.value"
                                        name="value"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        :readonly="form.base_status"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-select-group v-model="form.color"
                                         :options="getDefaultColors()"
                                         name="color"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="6" class="pt-4">
                        <b-icon font-scale="2" v-if="form.color" icon="hexagon-fill"
                                :style="'color:'+form.color"></b-icon>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <multiselect v-model="form.change_to_statuses"
                                     :options="getStatuses().map(status=>status.id)"
                                     :searchable="true"
                                     :multiple="true"
                                     placeholder=""
                                     select-label=""
                                     :close-on-select="false"
                                     :custom-label="opt => getStatuses().find(status=>status.id==opt)['name']"
                        >
                            <template slot="beforeList">
                                <li style="position: -webkit-sticky;position: sticky;top: 0;background-color: #f2f2f2;">
                                    <b-form-checkbox
                                        class="ml-2"
                                        :checked="isAll()"
                                        value="1"
                                        unchecked-value="0"
                                        @change="setAll"
                                    >
                                        All
                                    </b-form-checkbox>
                                </li>
                            </template>
                        </multiselect>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Status-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
                base_status: false,
            },
            form: {},
            labelPrefix: 'settings.statuses.label.',
        }
    },
    methods: {
        ...mapGetters('Status', ['getStatus', 'getStatuses']),
        ...mapGetters('CommonData', ['getDefaultColors']),
        shown() {
            const status = this.$store.dispatch('Status/fetchStatus', this.payload.id)
            const statusesFetch = this.$store.dispatch('Status/fetchStatuses')

            Promise.all([status, statusesFetch])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getStatus()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Status/saveStatus', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        isAll() {
            return this.form.change_to_statuses && this.form.change_to_statuses.length == this.getStatuses().length
        },
        setAll(val) {
            this.$set(this.form, 'change_to_statuses', [])
            if (val == 1) {
                this.getStatuses().forEach((status) => {
                    this.form.change_to_statuses.push(status.id)
                })
            }
        },
    }
}
</script>